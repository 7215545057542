import 'bootstrap'
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'

window._  = _
window.moment = moment
window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = document.head.querySelector('meta[name="csrf-token"]').content
window.axios.defaults.withCredentials = true

import { createApp, defineAsyncComponent } from 'vue/dist/vue.esm-bundler'
import { i18nVue, trans } from 'laravel-vue-i18n'
import { constants } from './includes/constants.js'
import * as utility from './includes/utility.js'

const app = createApp({
    data() {
        return {
            _: _,
            utility: utility,
            trans: trans,
            const: constants,
            menu: {
                mobile: false,
                business: false,
                individuals: false,
                resources: false,
                about: false
            }
        }
    },
    mounted() {
        this.guestMessage()
        if(window.toast) {
            this.$toast.add({
                severity: window.toast.severity,
                summary: window.toast.summary,
                detail: window.toast.detail
            })
        }
    },
    methods: {
        path(subdomain) {
            //returns specified path for use in vue components
            return window.path[subdomain]
        },
        scrollPageTo(id) {
            let element = document.querySelector('#' + id)
            element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        },
        scrollPageToTop(id) {
            let element = document.querySelector('#' + id)
            element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },
        staggerSlidefadeH(el) {
            // for the horizontal staggered transition
            el.classList.add('transition-slidefade-h-active')
            setTimeout(function() {
                el.classList.add('transition-slidefade-h-to')
            }, el.dataset.index * 400)
        },
        staggerSlidequickH(el) {
            // for the horizontal staggered transition
            el.classList.add('transition-slidefade-h-active')
            setTimeout(function() {
                el.classList.add('transition-slidefade-h-to')
            }, el.dataset.index * 100)
        },
        staggerSlidefadeV(el) {
            // for the vertical staggered transition
            el.classList.add('transition-slidefade-v-active')
            setTimeout(function() {
                el.classList.add('transition-slidefade-v-to')
            }, el.dataset.index * 400)
        },
        staggerSlidequickV(el) {
            // for the vertical staggered transition
            el.classList.add('transition-slidefade-v-active')
            setTimeout(function() {
                el.classList.add('transition-slidefade-v-to')
            }, el.dataset.index * 100)
        },
        toggleMenu(target) {
            if(target == 'mobile') {
                if(_.some(this.menu)) {
                    //a submenu is open, close everything
                    _.each(this.menu, (val, key) => {
                        this.menu[key] = false
                    })
                } else {
                    //open mobile menu
                    this.menu['mobile'] = true
                }
            } else {
                //close other menus
                _.each(this.menu, (val, key) => {
                    if(key == target) return
                    this.menu[key] = false
                })
                //toggle target menu
                this.menu[target] = this.menu[target] ? false : true
            }
        },
        guestMessage() {
            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)
            if(urlParams.has('prophit')) {
                this.$dialog.open(DialogGuestMessage, {
                    props: {
                        header: this.trans('You\'re in the right place. Prophit Co. is now Dignify!'),
                        modal: true,
                        closable: true,
                        draggable: false,
                        contentClass: 'p-0',
                        style: {
                            width: '602px',
                            maxWidth: '90vw'
                        }
                    }
                })
            }
        }
    }
})

app.use(i18nVue, {
    resolve: lang => import(`../../lang/${lang}.json`)
})

import UiCommon from './components/UiCommon.vue'
import UiLogos from './components/UiLogos.vue'
app.component('UiCommon', UiCommon)
app.component('UiLogos', UiLogos)

// PrimeVUE components
import PrimeVue from 'primevue/config'
import { definePreset } from '@primevue/themes'
import Lara from '@primevue/themes/lara'
import DialogService from 'primevue/dialogservice'
import ToastService from 'primevue/toastservice'

let customPreset = definePreset(Lara, {
    semantic: {
        primary: {
            50: 'var(--primary-color-50)',
            100: 'var(--primary-color-100)',
            200: 'var(--primary-color-200)',
            300: 'var(--primary-color-300)',
            400: 'var(--primary-color-400)',
            500: 'var(--primary-color-500)',
            600: 'var(--primary-color-600)',
            700: 'var(--primary-color-700)',
            800: 'var(--primary-color-800)',
            900: 'var(--primary-color-900)',
            950: 'var(--primary-color-950)',
        }
    }
})

app.use(PrimeVue, {
    theme: {
        preset: customPreset,
        options: {
            prefix: 'p',
            darkModeSelector: '.not-used',
            cssLayer: true
        }
    }
})
app.use(DialogService)
app.use(ToastService)

import Tooltip from 'primevue/tooltip'
app.directive('tooltip', Tooltip)

import DynamicDialog from 'primevue/dynamicdialog'
import Toast from 'primevue/toast'
app.component('DynamicDialog', DynamicDialog)
app.component('Toast', Toast)

const Dialog = defineAsyncComponent(() => import('primevue/dialog'))
app.component('Dialog', Dialog)

const PageRegistration = defineAsyncComponent(() => import('./components/survey/PageRegistration.vue'))
const PageSurvey = defineAsyncComponent(() => import('./components/survey/PageSurvey.vue'))
const UiRssFeed = defineAsyncComponent(() => import('./components/UiRssFeed.vue'))
const DialogGuestMessage = defineAsyncComponent(() => import('./components/dialog/GuestMessage.vue'))
app.component('PageRegistration', PageRegistration)
app.component('PageSurvey', PageSurvey)
app.component('UiRssFeed', UiRssFeed)
app.component('DialogGuestMessage', DialogGuestMessage)

const mounted = app.mount('#app') 

// Lets us access Vue from the global window object (i.e. in JS console)
window.app = mounted

